<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-toolbar color="transparent" flat height="55">
          <v-toolbar-title class="grey-500--text subtitle-1 font-weight-medium">
            <router-link :to="{name: 'AccountImports'}" class="subtitle-1 routerLink font-weight-medium">Importar</router-link>
            <v-icon class="mx-1" color="grey-500">mdi-chevron-right</v-icon>Clientes
          </v-toolbar-title>
          <v-spacer />
          <v-chip color="blue-500" text-color="white" small>Beta</v-chip>
        </v-toolbar>
        <v-divider />
        <v-card-text class="pa-0">
          <template v-if="status">
            <v-row class="mx-0">
              <v-col cols="12" class="white">
                <v-list class="v-list-form-extended transparent">
                  <v-list-item>
                    <v-list-item-title>Estado de la importación</v-list-item-title>
                    <v-list-item-subtitle>
                      <code :class="{'grey-500--text background': status === 'success'}">{{status}}</code>
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>Clientes importados</v-list-item-title>
                    <v-list-item-subtitle>{{count}}</v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
            <v-divider />
            <template v-if="issues">
              <v-toolbar color="transparent" flat height="55">
                <v-toolbar-title class="grey-500--text subtitle-1 font-weight-medium">Problemas</v-toolbar-title>
              </v-toolbar>
              <v-row>
                <v-timeline dense>
                  <v-timeline-item v-for="(issue, key) in issues" :key="key" color="grey-500" fill-dot right small>
                    <v-card class="elevation-1">
                      <v-card-title class="body-2 grey-500--text font-weight-medium pb-0 pt-3">{{key}}</v-card-title>
                      <v-card-text class="pb-3">
                        <template v-for="fields in issue">
                          <span class="d-block" v-for="(errors, field) in fields" :key="field">{{field}} {{errors.join(' ')}}</span>
                        </template>
                      </v-card-text>
                    </v-card>
                  </v-timeline-item>
                </v-timeline>
              </v-row>
            </template>
          </template>
          <template v-else>
            <v-row>
              <v-col cols="12" class="mx-auto">
                <v-list class="transparent" dense>
                  <v-list-item>
                    <v-list-item-title class="body-2 grey-500--text font-weight-medium">Requisitos</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Archivo en formato <span class="grey-500--text">csv, xlsx o json</span></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>El archivo debe contener al menos las columnas <span class="grey-500--text">name, address, state, country y email</span></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>El orden de las columnas no es importante</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title><v-btn href="/files/customers.import.example.xlsx" download text color="blue-500" :ripple="false"><v-icon color="blue-500" small left>mdi-download</v-icon>Descargar un archivo de ejemplo</v-btn></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
            <v-divider />
            <v-row>
              <v-col cols="12" class="mx-auto">
                <v-list class="transparent">
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-file-input v-model="file" type="file" accept=".csv,.xlsx,.json" :error="valid" prepend="mdi-paper-clip" background-color="transparent" hide-details placeholder="Seleccionar un archivo" solo flat chips />
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </template>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn @click="clearImport" v-if="status" :loading="$store.state.accounts.createLoader" color="blue-500">Nueva importación</v-btn>
          <v-btn @click="createImport" v-else :loading="$store.state.accounts.createLoader" color="blue-500">Importar</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import GenericViewMixin from '@/mixins/GenericViewMixin'
export default {
  mixins: [
    GenericViewMixin
  ],
  data: () => ({
    file: null,
    status: null,
    import_format: '',
    importFormats: ['csv', 'xlsx', 'json'],
    valid: false,
    count: null,
    issues: null
  }),
  methods: {
    createImport () {
      if (this.file == null) {
        return false
      }
      let array = this.file.name.split('.')
      this.import_format = array[array.length - 1]
      if (!this.importFormats.includes(this.import_format)) {
        this.valid = true
      }
      const payload = new FormData()
      payload.append('file', this.file)
      payload.append('import_format', this.import_format)
      this.$store.dispatch('accounts/CREATE', {
        resource: 'imports/customers',
        payload: payload,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        this.status = response.data.status
        this.count = response.data.count
        this.issues = response.data.issues
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
    },
    clearImport () {
      this.file = null
      this.status = null
      this.count = null
      this.issues = null
    }
  }
}
</script>